<template>
  <div>
    <!-- picF8DFBC2509AC23185A02B5B025553218.jpg -->
    <img class="rounded h-265" src="@/assets/news-network/create-digital-designs-the-lu-way-5.jpg">
    <p>
      <font size="4">Welcome</font> to the animation section of this special series on building the best creations in LEGO Universe! In this edition, lead animator Erik Beyer reveals a bit of the magic that makes the game’s LEGO models come alive.
    </p>

    <!-- pic306D6D3D347A4A8C283B3C1582392364.jpg -->
    <img class="rounded w-220 h-165 leftimage" src="@/assets/news-network/create-digital-designs-the-lu-way-5-1.jpg">

    <p>
      LEGO Universe art director, Phillip Atencio, sees the game’s animation as “a crucial way to communicate. Dramatic poses or strong gestures, for instance, can convey moods and emotions that draw players deeper into the LEGO Universe game experience.”
    </p>
    <p>
      <em><font size="1">Pictured: Erik Beyer, Lead animator</font></em>
    </p>
    <p>
      Erik clearly relishes the challenges of the work. Quick-witted and very funny, he describes his job as “awesome,” explaining that his team of talented animators gets to do things like “watch our favorite animated movies and call it ‘research’!”
    </p>
    <p>
      Erik points out that they do actually study motion during those sessions, and that’s just one of the steps they take on their way to the most fun part of their process. Before they can animate a virtual LEGO model, Erik’s team “rigs” and “skins” the creation.
    </p>
    <p>
      Rigging a model is like installing a skeleton by first putting in joint-like points. Virtual bones will be stretched between those joints, eventually allowing the model to move like a complex creation rather than a simple glob of goop!
    </p>
    <p>
      As for skinning, remember from part 3 how everything in virtual space is made-up of interconnected polygons? Well, skinning a LEGO model for animation simply means placing each of its polygons on a particular one of its bones.
    </p>
    <!-- MISSING: UniverseNewsNetworkFlashArticleItems - download3F994BF798639F7BF422325AC51631CA.swf -->
    <img class="rounded h-336 leftimage" src="">
    <p>
      Erik says skinning takes a lot of time, but strange things can happen if it’s not done perfectly. For example, if our LEGO Universe battle training mech was sloppily skinned, the blaster might tilt or twitch when the fingers curl into a fist!
    </p>
    <p>
      Once skinning is complete, Erik’s team loves pushing the creative boundaries of animation. He says, “For LEGO minifigures, we usually start with a relaxed pose, hands at the sides, then we’ll go nuts from there: back flips, helicopter jumps-- whatever we can dream up!”
    </p>
    <p>
      Players will definitely need to master an arsenal of those amazing moves to smash the battle mech! Erik is going to return in part 6 with more animations showing how his team is getting the machine ready to rumble LEGO Universe style!
    </p>
    <p>
      <strong>More LEGO Universe building tips:</strong>
      <br>- Consider how your LEGO model’s movements might give it character!
      <br>- Early planning can help your creation look cooler later!
      <br>- Find inspiration in your favorite creations!
    </p>
    <p>
      <em>Follow these links if you missed earlier editions of this special series!</em><br>
      <router-link :to="{ name: 'story', params: {id: 82873} }">
        Creating digital designs the LEGO Universe way: Part 1
      </router-link><br>
      <router-link :to="{ name: 'story', params: {id: 88803} }">
        Creating digital designs the LEGO Universe way: Part 2
      </router-link><br>
      <router-link :to="{ name: 'story', params: {id: 91070} }">
        Creating digital designs the LEGO Universe way: Part 3
      </router-link><br>
      <router-link :to="{ name: 'story', params: {id: 107581} }">
        Creating digital designs the LEGO Universe way: Part 4
      </router-link>
    </p>
  </div>
</template>
